<template>
  <div class="bg-container">
    <div id="box-sign-in">
      <div class="text-center">
        <img src="@/assets/logo-login.png" alt="" />
      </div>
      <p class="text-center color-35 font-30 pd-t-2">Sign in</p>
      <el-form label-position="top" :model="form" ref="form" :rules="rules">
        <el-form-item label="Email" prop="email">
          <el-input
            v-model="form.email"
            @keyup.enter.native="checkValidate"
          ></el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password" class="mg-b-0">
          <el-input
            type="password"
            v-model="form.password"
            @keyup.enter.native="checkValidate"
            show-password
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="text-right color-green-light">
        <a
          href="https://dtm.avalue.co.th/password/forgotPassword"
          target="_blank"
          ><small>Forgot password?</small></a
        >
      </p>
      <el-button
        type="primary"
        v-loading.fullscreen.lock="fullscreenLoading"
        class="w-100 mg-t-6"
        round
        @click="checkValidate"
        >Sign in</el-button
      >
      <div class="text-center mg-t-1">
        <span class="color-35 font-16"> Don't have an account?</span>
        <a
          :href="`https://dtm.avalue.co.th/register?redirect=${profileLearnerUrl}`"
          target="_blank"
        >
          <span class="color-green-light font-16"> Sign up Now!</span></a
        >
      </div>
      <div id="footer">
        <span class="font-12 color-c4 font-weight-400"
          >© Copyright Digital Talent Acedemy 2021</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { HTTPDtm } from "@/service/axios";
import { HTTP } from "@/service/axios";

export default {
  data() {
    return {
      providerId: process.env.VUE_APP_DTM_PROVIDER_ID,
      providerKey: process.env.VUE_APP_DTM_KEY,
      profileLearnerUrl: process.env.VUE_APP_BASE_URL,
      fullscreenLoading: false,
      user: null,
      accessToken: "",
      refreshToken: "",
      form: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          {
            required: true,
            message: "กรุณากรอกข้อมูล",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกข้อมูล",
            trigger: "blur"
          }
        ]
      }
    };
  },
  async mounted() {
    // this.fullscreenLoading = true;
    // let tokenCookie = {
    //   providerId: this.providerId,
    //   key: this.providerKey
    // }
    // await HTTPDtm.post(`getCookie`, tokenCookie,{withCredentials: true})
    //     .then(async (res) => {
    //       if (res.data.dtmSession) {
    //         this.accessToken = res.data.dtmSession;
    //         this.refreshToken = res.data.dtmSession;
    //         HTTPDtm.defaults.headers.common.Authorization =
    //           `Bearer ` + res.data.dtmSession;
    //         await HTTPDtm.get(`profile`)
    //           .then((res) => {
    //             if (res.data.successful) {
    //               this.user = res.data;
    //               this.savaUser();
    //               //this.singInToken();
    //             }
    //           })
    //           .catch((e) => {
    //             console.log(e);
    //             // window.location.href = "https://dtm.avalue.co.th/login/account?redirect="+ process.env.VUE_APP_BASE_URL;
    //           });
    //       }else{
    //         // window.location.href = "https://dtm.avalue.co.th/login/account?redirect="+ process.env.VUE_APP_BASE_URL;
    //         console.log("goto login",res);
    //       }
    //     })
    //     .catch((e) => {
    //       this.$message({
    //         showClose: true,
    //         message: "กรุณาเข้าสู่ระบบอีกครั้ง",
    //         type: "error",
    //         duration: 3000,
    //       });
    //       this.fullscreenLoading = false;
    //       // window.location.href = "https://dtm.avalue.co.th/login/account?redirect="+ process.env.VUE_APP_BASE_URL;
    //     });
  },
  methods: {
    checkValidate() {
      this.$refs["form"].validate().then(valid => {
        if (valid) {
          this.fullscreenLoading = true;
          this.singIn();
        }
      });
    },

    async singIn() {
      let singIn = {
        email: this.form.email,
        password: this.form.password
      };
      await HTTPDtm.post(`login`, singIn)
        .then(res => {
          if (res.data.successful) {
            console.log("res.data", res.data);
            this.accessToken = res.data.accessToken;
            this.refreshToken = res.data.refreshToken;
            HTTPDtm.defaults.headers.common.Authorization =
              `Bearer ` + res.data.accessToken;
            HTTPDtm.get(`profile`)
              .then(async res => {
                console.log("HTTPDtm", res);
                if (res.data.successful) {
                  this.user = res.data;
                  await this.savaUser();
                }
              })
              .catch(e => {
                console.log(e);
              });
          }
        })
        .catch(e => {
          this.$message({
            showClose: true,
            message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง",
            type: "error",
            duration: 3000
          });
          this.fullscreenLoading = false;
        });
    },

    async savaUser() {
      console.log("this.user", this.user);
      let User = {
        id: this.user.id,
        kyc: this.user.kyc,
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        phoneNumber: this.user.phoneNumber,
        isVerify: this.user.isVerify,
        accessToken: this.accessToken,
        refreshToken: this.refreshToken,
        profileImage: this.user.profileImage,
        profileImageId: this.user.profileImageId
      };
      console.log("User", User);
      await HTTP.post(`save/user`, User)
        .then(res => {
          console.log("Save User", res);
          if (res.data.success) {
            // this.singInToken();
            this.$store.commit("SET_USER", res.data.obj);
            this.fullscreenLoading = false;
            console.log("router", this.$router.history.current.query.redirect);
            this.$router.history.current.query.redirect
              ? (window.location = this.$router.history.current.query.redirect)
              : this.$router.push(`/calendar`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    singInToken() {
      let userLogin = {
        email: this.form.email,
        password: this.form.password
      };
      HTTP.post(`login`, userLogin)
        .then(res => {
          if (res.data.success) {
            this.$store.commit("SET_USER", res.data.obj);
            this.fullscreenLoading = false;
            this.$router.push(`/calendar`);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>
